<template>
    <div class="backdrop" @click.self="closeModal()">
        <LoadingSpinner v-if="isLoading" />
        <section v-else class="popup flex flex-column justify-center align-center form-container">
            <h2 class="title">{{notify}}</h2>
            <p class="info">{{fillInYourEmail}}</p>
            <form @submit.prevent="subscribeForNotification()">
                <div class="form-row flex flex-column">
                    <label for="email">{{emailAddress}} *</label>
                    <input 
                        id="email" 
                        type="email"
                        v-model.trim="email"
                        @input="$v.email.$touch()"
                        :class="{ error: $v.email.$error }" 
                    />
                    <div v-if="$v.email.$dirty">
                        <p class="error" v-if="!$v.email.email">{{validEmailAddress}}</p>
                        <p class="error" v-if="!$v.email.required">Email {{isRequired}}</p>
                    </div>
                </div>

                <div class="form-row">
                    <span class="small">* {{indicatesRequiredField}}</span>
                </div>

                <div class="form-row">
                    <input type="checkbox" id="optin" v-model="optin" @change="$v.optin.$touch()" :class="{ error: $v.optin.$error }">
                    <label for="optin"><div class="custom-checkbox"></div>{{OptInRecieveEmail}}</label>
                    <div v-if="$v.optin.$dirty && $v.optin.$invalid">
                        <p class="error" v-if="$v.optin.required">{{OptInRecieveEmail}} {{isRequired}}</p>
                    </div>
                </div>         
                
                <div class="form-row flex justify-center">
                    <button type="submit" class="btn btn-primary alternative">{{submit}}</button>
                </div>                      
            </form>
        </section>
        <WarningMessageModal v-show="showWarningModal" :title="errorTitle" :text="errorMessage" @closeModal="showWarningModal = false" />
    </div>
</template>

<script>
import agent from '../../api/agent';
import WarningMessageModal from './WarningMessageModal.vue';
import LoadingSpinner from '../ui/LoadingSpinner.vue';
import { mapGetters } from 'vuex';
import { required, email, sameAs } from 'vuelidate/lib/validators';


export default {
    name: 'NotificationModal',
    components: {WarningMessageModal, LoadingSpinner},
    props: {
        productId: Number
    },
    data() {
        return {
            email: '',
            optin: false,
            showWarningModal: false,
            errorTitle: '',
            errorMessage: '',
            isLoading: false
        }
    },

    computed: {
        ...mapGetters([
            'selectedLanguage',
            // Texts
            'notify',
            'fillInYourEmail',
            'emailAddress',
            'validEmailAddress',
            'isRequired',
            'indicatesRequiredField',
            'submit',
            'OptInRecieveEmail',
            'notAllRequiredFieldsAreCompleted'
        ]),      
    },

    methods: {
        showModal() {
            this.showWarningModal = true;
        },

        closeModal() {
            this.$emit('closeModal', false);
        },

        async subscribeForNotification() {
            
            if (this.$v.email.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.emailAddress + ' ' + this.isRequired;
                this.showWarningModal = true;
                return;
            }

            if (this.$v.optin.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.OptInRecieveEmail + ' ' + this.isRequired;
                this.showWarningModal = true;
                return;
            }

            const requestData = {
                email: this.email, 
                productId: this.productId, 
                languageNumber: this.selectedLanguage, 
                optInConfirmation: this.optin
            };

            try {
                this.isLoading = true;
                await agent.products.addNotification(requestData);
                this.closeModal();
                this.isLoading = false;
            } catch (error) {
                console.error(error);
                // console.log(error.response);
                this.isLoading = false;
                // this.errorTitle = error.response.data.title;
                // this.errorMessage = error.response.data.detail;
                // this.showWarningModal = true;
            }
        },
    },

    validations: {
        email: { email, required },
        optin: {required, sameAsChecked: sameAs(() => true)}

    },
}
</script>

<style lang="scss" scoped>
@import '@/global-styles/variables';

.backdrop {
    background-color: rgba($black, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    cursor: pointer;
}
.popup {
    position: absolute;
    top: 30rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: $white;
    border-radius: 0.8rem;
    padding: 5rem 10rem;
    box-shadow: 0 0 33px 3px rgba(0, 0, 0, 0.22);
}

form {
    width: 40rem;
}

.title {
    font-size: 3rem;
    margin-bottom: 2rem;
}

.info {
    font-size: 1.8rem;
    margin-bottom: 5rem;
}
</style>